import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import styled from 'styled-components'
import { position, opacity, layout } from 'styled-system'
import GoogleFontLoader from 'react-google-font-loader'

// components
import Header from './globals/Header/Header'
import TransitionRoute from './globals/TransitionRoute/TransitionRoute'
import Projects from './pages/projects/Projects'
import About from './pages/about/About'
import Contact from './pages/contact/Contact'

// assets for preload
import * as HeroImages from './pages/projects/components/Hero/images'

// hack around because styled-system crashes if breakpoints can't use map function
const breakpoints = []
breakpoints.S = '768px'
breakpoints.M = '1024px'
breakpoints.L = '1280px'

const LoadingShade = styled.div.attrs(({ isLoaded }) => ({
    position: isLoaded ? 'static' : 'fixed',
    overflow: isLoaded ? 'auto' : 'hidden',
    opacity: isLoaded ? 1 : 0,
}))`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity 0.25s ease;
    ${position};
    ${opacity};
    ${layout};
`

// helpers
const getImageUrls = (imports = {}) =>
    Object.entries(imports).map(([key, url]) => url)

const loadAllImages = (images) =>
    new Promise((resolve) => {
        let imagesLoaded = 0
        images.forEach((imageUrl) => {
            var image = new Image()
            image.onload = function () {
                imagesLoaded = imagesLoaded + 1
                if (imagesLoaded >= images.length) {
                    resolve()
                }
            }
            // handle failure
            image.onerror = function (err) {
                console.error(err)
                imagesLoaded = imagesLoaded + 1
                if (imagesLoaded >= images.length) {
                    resolve()
                }
            }
            image.src = imageUrl
        })
    })

const App = () => {
    const [preloadComplete, setPreloadComplete] = useState(false)

    useEffect(() => {
        const preloadImages = async () => {
            const images = getImageUrls(HeroImages)

            try {
                await loadAllImages(images)
                setPreloadComplete(true)
            } catch (err) {
                console.error(err)
                setPreloadComplete(true)
            }
        }

        preloadImages()
    }, [setPreloadComplete])

    return (
        <ThemeProvider
            theme={{
                disableStyledSystemCache: true,
                breakpoints,
            }}
        >
            <>
                <GoogleFontLoader
                    fonts={[
                        {
                            font: 'Space Mono',
                            weights: [400, 700],
                        },
                        {
                            font: 'Open Sans',
                            wights: [400, 700],
                        },
                    ]}
                />
                <Router>
                    <>
                        <Header isLoaded={preloadComplete} />
                        <LoadingShade isLoaded={preloadComplete}>
                            <TransitionRoute path="/about" component={About} />
                            <TransitionRoute
                                path="/contact"
                                component={Contact}
                            />
                            <TransitionRoute
                                path="/"
                                component={Projects}
                                isLoaded={preloadComplete}
                            />
                        </LoadingShade>
                    </>
                </Router>
            </>
        </ThemeProvider>
    )
}

export default App
