import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { height, display, space, position, flexbox } from 'styled-system'
import { useHistory } from 'react-router-dom'
import _findIndex from 'lodash/findIndex'

// components
import { H1, Link } from '../../../../globals/Typography'

// assets
import Hero1Image from './images/hero-1.png'
import Hero2Image from './images/hero-2.png'
import Hero3Image from './images/hero-3.png'
import Hero4Image from './images/hero-4.png'
import Hero5Image from './images/hero-5.png'
import Hero6Image from './images/hero-6.png'

// styled components
const Container = styled.div.attrs(() => ({}))`
    position: relative;
    transition: opacity 0.5s ease;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${({ background }) => background};
    transition: background 1s ease, opacity 0.5s ease;
    opacity: ${({ height }) => (height ? 1 : 0)};
    overflow: hidden;
    ${height};
    ${space};
`

const Content = styled.div`
    position: relative;
`

const Copy = styled.div`
    position: relative;
    z-index: 2;
    text-align: center;
    color: #ffffff;
    padding: 0 1rem;
    line-height: 1.5rem;
`

const Headline = styled(H1).attrs(() => ({
    flexDirection: { _: 'column', S: 'row' },
    justifyContent: 'center',
}))`
    margin-bottom: 0.75rem;
    text-transform: uppercase;
    display: flex;
    ${flexbox}
`
const BreakLine = styled.span.attrs(() => ({
    mr: { _: 0, S: '1rem' },
}))`
    ${space};
`

const SubHeadline = styled(Link)`
    margin: 0;
    line-height: 1rem;
    font-size: 12px;
`

const Gem = styled.div.attrs(() => ({
    left: { _: 0, S: '50%' },
    top: { _: '100%', S: '50%' },
}))`
    position: absolute;
    transform: translateY(-50%);
    transition: opacity 1s ease;
    opacity: ${({ active }) => (active ? 1 : 0)};
    img {
        vertical-align: top;
        height: 400px;
        width: auto;
    }
    z-index: 1;
    ${space};
    ${position};
`
// responsive <br /> for responsive copy layout
const Br = styled.br.attrs(() => ({
    display: {
        _: 'initial',
        S: 'none',
    },
}))`
    ${display};
`

const gems = [
    {
        id: 'hero1',
        background: '#000000',
        image: Hero1Image,
    },
    {
        id: 'hero2',
        background: '#80CBC4',
        image: Hero2Image,
    },
    {
        id: 'hero3',
        background: '#4DD0E1',
        image: Hero3Image,
    },
    {
        id: 'hero4',
        background: '#FFCDD2',
        image: Hero4Image,
    },
    {
        id: 'hero5',
        background: '#EC407A',
        image: Hero5Image,
    },
    {
        id: 'hero6',
        background: '#FFCA28',
        image: Hero6Image,
    },
]

const Hero = ({ siteIsLoaded }) => {
    const [containerHeight] = useState(window.innerHeight)
    const [currentGem, setCurrentGem] = useState(gems[0])

    // router hooks
    const { push } = useHistory()

    useEffect(() => {
        if (siteIsLoaded) {
            const rotateGems = setTimeout(() => {
                const currentGemIndex = _findIndex(gems, { id: currentGem.id })
                const nextGem = gems[currentGemIndex + 1]
                    ? gems[currentGemIndex + 1]
                    : gems[0]
                setCurrentGem(nextGem)
            }, 3000)

            return () => {
                clearTimeout(rotateGems)
            }
        }
    }, [currentGem, siteIsLoaded])

    return (
        <Container background={currentGem.background} height={containerHeight}>
            <Content>
                <Copy>
                    <Headline>
                        <BreakLine>Hello,</BreakLine>
                        <span>I'm Beryl</span>
                    </Headline>
                    <SubHeadline onClick={() => push('/about')}>
                        Creative Director / Illustrator / Designer / <Br />
                        Semi-precious Mineral
                    </SubHeadline>
                </Copy>
                {gems.map(({ id, image }) => (
                    <Gem key={id} active={id === currentGem.id}>
                        <img src={image} alt={`${id}-gem`} />
                    </Gem>
                ))}
            </Content>
        </Container>
    )
}

Hero.propTypes = {
    siteIsLoaded: PropTypes.bool.isRequired,
}

export default Hero
