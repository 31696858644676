import styled from 'styled-components'
import { typography, space, system } from 'styled-system'

const H1 = styled.h1`
    line-height: 2.25rem;
    font-size: 2rem;
    font-weight: 700;
    ${space};
`

const H2 = styled.h2.attrs(() => ({
    fontSize: { _: '1.125rem', S: '1.5rem' },
}))`
    line-height: 1.375rem;
    font-weight: 700;
    ${typography};
    ${space};
`

const H3 = styled.h3`
    line-height: 1.375rem;
    font-size: 1.125rem;
    font-weight: 700;
    ${space};
`

const H4 = styled.h4`
    line-height: 1.375rem;
    font-size: 1rem;
    font-weight: 700;
    ${space};
`

const H5 = styled.h5`
    line-height: 1.25rem;
    font-size: 0.75rem;
    font-weight: 400;
    ${space};
`

const Paragraph = styled.p.attrs(({ fontSize, fontWeight, mb }) => ({
    fontWeight: fontWeight || 400,
    fontSize: fontSize || '0.75rem',
    mb: mb || '1rem',
}))`
    line-height: 1.25rem;
    margin: 0;
    ${typography};
    ${space};
`

const textDecoration = system({
    prop: 'textDecoration',
})
const Link = styled.a.attrs(() => ({
    textDecoration: { _: 'underline', S: 'none' },
}))`
    cursor: pointer;
    color: inherit;

    ${textDecoration};
    &:hover {
        text-decoration: underline;
    }
`

export { H1, H2, H3, H4, H5, Paragraph, Link }
