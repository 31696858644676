import React from 'react'
import styled from 'styled-components'
import getYear from 'date-fns/getYear'

// components
import { Paragraph } from '../Typography'

// styled
const FooterText = styled(Paragraph).attrs(() => ({
    textAlign: 'center',
    p: '0.75rem 1rem',
    mb: 0,
}))`
    width: 100%;
    color: #666666;
`

const Footer = () => {
    const currentYear = getYear(Date.now())
    return <FooterText>Beryl Chung &copy;{currentYear}</FooterText>
}

export default Footer
