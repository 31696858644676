import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const TransitionRoute = ({ component: Component, path, ...componentProps }) => {
    const { pathname } = useLocation()

    const [currentPath, setCurrentPath] = useState(pathname)
    const [previousPath, setPreviousPath] = useState('')

    useEffect(() => {
        if (pathname !== currentPath) {
            setPreviousPath(currentPath)
            setCurrentPath(pathname)
        }
    }, [pathname, currentPath])

    const isCurrentPath = useMemo(() => currentPath === path, [
        currentPath,
        path,
    ])
    const isPreviousPath = useMemo(() => previousPath === path, [
        previousPath,
        path,
    ])

    return (
        <Component
            isActive={currentPath === path}
            transitionIn={isCurrentPath}
            transitionOut={isPreviousPath}
            {...componentProps}
        />
    )
}

export default TransitionRoute
