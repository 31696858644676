import React, { useMemo } from 'react'
import styled from 'styled-components'
import { opacity, system } from 'styled-system'
import FilePlayer from 'react-player/lib/players/FilePlayer'
import { Flex } from 'rebass/styled-components'

// components
import { Paragraph } from '../../../../globals/Typography'
import FwaIcon from '../../../../globals/icons/components/Fwa'
import AwwwardsIcon from '../../../../globals/icons/components/Awwwards'
import SpikesAsiaIcon from '../../../../globals/icons/components/SpikesAsia'
import ClioIcon from '../../../../globals/icons/components/Clio'
import VideoPlayer from './VideoPlayer'
import { buttonStyles } from '../../../../globals/Button/Button'
import CannesLionsIcon from '../../../../globals/icons/components/CannesLions'
import AnthemAwardsIcon from '../../../../globals/icons/components/AnthemAwards'
import CssWinnerIcon from '../../../../globals/icons/components/CssWinner'
import OneShowIcon from '../../../../globals/icons/components/OneShow'
import CampaignExperienceIcon from '../../../../globals/icons/components/CampaignExperienceAward'
import WebbyIcon from '../../../../globals/icons/components/Webby'
import Eurobest from '../../../../globals/icons/components/Eurobest'

const awardIcons = {
    fwa: <FwaIcon height="1.25rem" />,
    awwwards: <AwwwardsIcon height="1rem" />,
    spikesAsia: <SpikesAsiaIcon height="1.375rem" />,
    clio: <ClioIcon height="1rem" />,
    cannesLions: <CannesLionsIcon height="1.5rem" />,
    anthemAwards: <AnthemAwardsIcon width="6.125rem" height="1.5rem" />,
    campaignExperienceAward: <CampaignExperienceIcon height="1.25rem" />,
    cssWinner: <CssWinnerIcon height="1rem" />,
    oneShow: <OneShowIcon height="1.25rem" />,
    webbyAwards: <WebbyIcon height="1.5rem" />,
    eurobest: <Eurobest height="1rem" />,
}

// styled components
const BackgroundImage = styled.div.attrs(() => ({
    opacity: { _: 0.15, S: 0 },
}))`
    z-index: 1;
    position: absolute;
    display: block;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: ${({ videoIsActive }) => (videoIsActive ? 0 : 1)};

    /* animated properties */
    transform: scale(1);
    transition: transform 0.25s ease;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000;
        ${opacity};
        transition: opacity 0.25s ease;
        z-index: 2;
    }
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;

    img,
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
    }
`

const transform = system({
    property: 'transform',
})
const Content = styled.div.attrs(() => ({
    opacity: { _: 1, S: 0 },
    transform: { _: 'translateY(0%)', S: 'translateY(5%)' },
}))`
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${opacity};
    ${transform};
    transition: opacity 0.15s ease, transform 0.25s ease;
`

const InfoContainer = styled(Flex).attrs(() => ({
    flexDirection: 'column',
    alignItems: 'center',
    width: 1,
}))``

const Info = styled(Flex).attrs(() => ({
    width: 1,
    justifyContent: 'center',
}))`
    position: relative;
`

const Title = styled(Paragraph).attrs(() => ({
    mb: 0,
}))`
    position: absolute;
    color: #fff;
    font-family: 'Space Mono', monospace;
    bottom: calc(100% + 1rem);
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    line-height: 1rem;
`

const Awards = styled(Flex).attrs(() => ({
    justifyContent: 'center',
    alignItems: 'center',
}))`
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;
    width: 100%;
`
const AwardIcon = styled.div`
    vertical-align: top;
    color: #ffffff;
    &:not(:last-child) {
        margin-right: 1.5rem;
    }
    svg {
        display: block;
    }
`

const Container = styled.div.attrs(({ href }) => ({
    as: href ? 'a' : 'button',
}))`
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &:before {
        content: '';
        padding-bottom: 56.25%;
        display: block;
        width: 100%;
    }

    /* transition properties */
    &:hover {
        cursor: pointer;
        ${BackgroundImage} {
            transform: scale(1.01);
            &:before {
                opacity: 0.25;
            }
        }
        ${Content} {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    /* button/link resets */
    outline: none;
    border: none;
    padding: 0;
`

const FauxButton = styled.div`
    ${buttonStyles}
`

const Project = ({
    id,
    title,
    video = {},
    preview,
    previewVideo,
    website,
    awards,
    setAsActive,
    activeProject,
}) => {
    const isActive = useMemo(() => activeProject === id, [activeProject, id])

    const containerProps = useMemo(
        () =>
            website
                ? {
                      href: website,
                      target: '_blank',
                  }
                : {
                      onClick: () => setAsActive(id),
                  },
        [website, id, setAsActive]
    )

    return (
        <Container {...containerProps}>
            <BackgroundImage videoIsActive={isActive}>
                {previewVideo ? (
                    <FilePlayer
                        url={previewVideo}
                        playing={true}
                        loop={true}
                        controls={false}
                        width="100%"
                        height="100%"
                        config={{
                            file: {
                                attributes: {
                                    preload: 'auto',
                                },
                            },
                        }}
                    />
                ) : (
                    <img src={preview} alt={title} />
                )}
            </BackgroundImage>

            <Content>
                <InfoContainer>
                    <Info>
                        <Title>{title}</Title>

                        <FauxButton>
                            {website ? 'CHECK IT OUT' : 'PLAY'}
                        </FauxButton>
                        {awards && (
                            <Awards>
                                {awards.map((award) => {
                                    return awardIcons[award] ? (
                                        <AwardIcon key={award}>
                                            {awardIcons[award]}
                                        </AwardIcon>
                                    ) : (
                                        false
                                    )
                                })}
                            </Awards>
                        )}
                    </Info>
                </InfoContainer>
            </Content>
            {video.url && <VideoPlayer {...video} isActive={isActive} />}
        </Container>
    )
}

export default Project
