import * as React from 'react'
function SvgOneShow(props) {
    return (
        <svg
            id="oneShow_svg__Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x={0}
            y={0}
            viewBox="0 0 281.3 206.6"
            xmlSpace="preserve"
            {...props}
        >
            <style>{'.oneShow_svg__st0{fill:#fff}'}</style>
            <path
                className="oneShow_svg__st0"
                d="M70.5 116.6c0 3.4 2.7 6.1 6.2 6.1h4.2c3.4 0 6.1-2.7 6.1-6.1V91.3c0-3.5-2.6-6.2-6.1-6.2h-4.2c-3.4 0-6.2 2.7-6.2 6.2v25.3"
            />
            <path
                className="oneShow_svg__st0"
                d="M227.7.2h-71.5c-1.3 0-3.2.8-4.1 1.7L77.8 76.2h3c9.6 0 15.9 6.1 15.9 15.1v25.3c0 9-6.3 15.1-15.9 15.1h-4.2c-9.7 0-15.9-6.1-15.9-15.1V93.2l-35.5 35.5c-.9.9-1.7 2.8-1.7 4.1v71.5c0 1.3 1.1 2.4 2.4 2.4h71.5c1.3 0 3.2-.8 4.1-1.7l74.2-74.2h-21.6c-.6 0-.8-.3-.8-.9v-52c0-.6.3-.9.8-.9h30.2c.5 0 .9.3.9.9v7.3c0 .6-.3.9-.9.9H164c-.6 0-.9.3-.9.9v11.4c0 .6.3.9.9.9h16.7c.6 0 .9.3.9.9v7.3c0 .6-.3.9-.9.9H164c-.6 0-.9.3-.9.9V121c0 .6.3.9.9.9h20.3c.1 0 .2 0 .3.1l43.7-43.7c.9-.9 1.7-2.8 1.7-4.1V2.7c.1-1.4-1-2.5-2.3-2.5zM141.8 130c0 .6-.3.9-.9.9h-7.1l-.8-.4-17.5-34.3.5 33.8c0 .6-.3.9-.9.9h-7.9c-.6 0-.9-.3-.9-.9V77.9c0-.6.3-.9.9-.9h7.2l.8.4 17.4 34.7-.3-34.2c0-.6.3-.9.9-.9h7.9c.5 0 .9.3.9.9V130M13.2 79.9H9.3c-.3 0-.4.1-.4.4v18.1c0 .3-.1.4-.4.4H5.2c-.3 0-.4-.1-.4-.4V80.3c0-.3-.1-.4-.4-.4H.6c-.3 0-.4-.1-.4-.4v-3c0-.3.1-.4.4-.4h12.6c.2 0 .4.1.4.4v3c0 .3-.1.4-.4.4zM31.7 98.7h-3.4c-.3 0-.4-.1-.4-.4v-8.8c0-.3-.1-.4-.4-.4H21c-.3 0-.4.1-.4.4v8.8c0 .3-.1.4-.4.4h-3.4c-.3 0-.4-.1-.4-.4V76.5c0-.3.1-.4.4-.4h3.4c.2 0 .4.1.4.4V85c0 .3.1.4.4.4h6.7c.3 0 .4-.1.4-.4v-8.5c0-.3.1-.4.4-.4h3.4c.3 0 .4.1.4.4v21.9c-.2.2-.3.3-.6.3zM49 98.7H36.4c-.3 0-.4-.1-.4-.4V76.5c0-.3.1-.4.4-.4H49c.2 0 .4.1.4.4v3c0 .3-.1.4-.4.4h-8.5c-.3 0-.4.1-.4.4V85c0 .3.1.4.4.4h7c.3 0 .4.1.4.4v3c0 .3-.1.4-.4.4h-7c-.3 0-.4.1-.4.4v5.1c0 .3.1.4.4.4H49c.2 0 .4.1.4.4v3c0 .1-.1.2-.4.2z"
            />
            <g>
                <path
                    className="oneShow_svg__st0"
                    d="M206.3 114.1c0 1.7 1.6 2.2 4.2 3.3 3.8 1.8 5.6 3.7 5.6 7.3 0 3.8-2.6 6.3-6.7 6.3H208c-2 0-3.6-.6-4.7-1.7-1.2-1.2-1.6-2.3-1.6-2.6 0-.1.1-.2.2-.3l3.1-1.5h.2c.3 0 .3.4.7 1.1.4.8 1.3 1.3 2.1 1.3h1.4c1.4 0 2.6-1.1 2.6-2.6 0-1.8-1.5-2.4-3.8-3.5-3.9-1.9-5.9-3.5-5.9-7.1 0-3.8 2.7-6.3 6.7-6.3h.5c1.6 0 2.9.4 4.1 1.2 1.4 1 2.3 2.6 2.3 3 0 .1-.1.2-.2.3l-3.1 1.5h-.2c-.2 0-.3-.2-.5-.5-.7-1.1-1.3-1.8-2.4-1.8h-.5c-1.5 0-2.7 1.2-2.7 2.6zM234 130.7h-3.4c-.3 0-.4-.1-.4-.4v-8.8c0-.3-.1-.4-.4-.4h-6.7c-.3 0-.4.1-.4.4v8.8c0 .3-.1.4-.4.4h-3.4c-.3 0-.4-.1-.4-.4v-21.9c0-.3.1-.4.4-.4h3.4c.2 0 .4.1.4.4v8.5c0 .3.1.4.4.4h6.7c.3 0 .4-.1.4-.4v-8.5c0-.3.1-.4.4-.4h3.4c.3 0 .4.1.4.4v21.9c0 .3-.1.4-.4.4zM246.2 131.1h-1.8c-4 0-6.7-2.5-6.7-6.3v-10.6c0-3.8 2.7-6.3 6.7-6.3h1.8c4 0 6.7 2.5 6.7 6.3v10.6c0 3.7-2.7 6.3-6.7 6.3zm2.6-17c0-1.5-1.1-2.6-2.5-2.6h-1.8c-1.4 0-2.6 1.1-2.6 2.6v10.6c0 1.4 1.1 2.6 2.6 2.6h1.8c1.4 0 2.5-1.1 2.5-2.6v-10.6zM273.5 130.4c0 .2-.2.3-.4.3h-2.6c-.2 0-.3-.1-.4-.3l-3.6-14.2-3.6 14.2c0 .2-.1.3-.4.3H260c-.2 0-.3-.1-.4-.3l-4.7-21.9v-.1c0-.2.1-.3.4-.3h3.7c.2 0 .3.1.4.3L262 123l3.4-14.7c0-.2.1-.3.4-.3h1.8c.2 0 .3.1.4.3l3.4 14.7 2.7-14.6c0-.2.2-.3.4-.3h3.7c.3 0 .4.1.4.3v.1l-5.1 21.9z"
                />
            </g>
        </svg>
    )
}
export default SvgOneShow
