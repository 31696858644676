import * as React from 'react'
function SvgClio(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 216.804 841.889 161.727"
            {...props}
        >
            <g fill="currentColor">
                <path d="M309.215 217.135h35.292v146.994h92.462v8.74H309.215V217.135zM492.655 217.135h35.072v155.734H492.6V217.135h.055zM713.311 378.476c-72.508 0-128.799-33.368-128.799-80.863.001-46.286 56.291-80.809 128.799-80.809s128.578 34.522 128.578 80.863c0 47.441-56.07 80.809-128.578 80.809zm90.648-80.808c0-42.328-36.336-72.123-90.648-72.123s-90.648 29.795-90.648 72.123c0 42.548 36.337 72.123 90.648 72.123 54.312 0 90.648-29.575 90.648-72.123zM212.026 328.507c-13.248 25.287-43.373 41.229-83.228 41.229-54.312 0-90.647-29.575-90.647-72.123 0-42.328 36.336-72.123 90.647-72.123 39.8 0 69.869 16.052 83.172 41.229h36.337c-18.69-29.904-64.372-49.914-119.509-49.914C56.291 216.804 0 251.327 0 297.668c0 47.495 56.291 80.863 128.798 80.863 55.467 0 101.312-19.515 119.783-49.969h-36.556v-.055z" />
            </g>
        </svg>
    )
}
export default SvgClio
