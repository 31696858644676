import React from 'react'
import { Flex, Box } from 'rebass/styled-components'
import styled from 'styled-components'
import { display } from 'styled-system'

// components
import SecondaryPage from '../../globals/SecondaryPage/SecondaryPage'
import { Paragraph, Link } from '../../globals/Typography'

// assets
import BerylPortraitImg from './images/profile.jpg'
import MineralsImg from './images/minerals.png'
import PeriodicTableImg from './images/periodicTable.png'
import BerylGeometricImg from './images/berylGeometric.png'

// data
const featuredLinks = [
    {
        name: 'Drift Compass | Portrait Project',
        url: 'https://youtu.be/NIHBQGYyEkM',
    },
    {
        name: 'Juxtapoz Magazine',
        url: 'https://www.juxtapoz.com/news/illustration/beryl-chungs-dedication-to-the-king-of-rock-and-roll/',
    },
    {
        name: 'Giant Robot NY',
        url: 'https://www.giantrobot.com/blogs/giant-robot-store-and-gr2-news/15827171-grny-dime-bag-3-group-show-7-18-09-8-12-09',
    },
]

// styled
const IntroParagraph = styled(Paragraph).attrs(() => ({
    fontSize: '1rem',
    fontWeight: '700',
    mb: { _: '1rem', S: '2rem' },
}))`
    line-height: 1.5rem;
    font-family: 'Space Mono', monospace;
`

const Image = styled.img`
    width: 100%;
    height: auto;
    vertical-align: top;
`

const ResponsiveDisplayBox = styled(Box)`
    ${display};
`

const FeaturedLinks = styled.p`
    font-family: 'Space Mono', monospace;
    font-size: 0.625rem;
`

const WikiText = styled(Paragraph)`
    font-family: 'Space Mono', monospace;
`

const About = ({ transitionIn, transitionOut }) => {
    return (
        <SecondaryPage
            transitionIn={transitionIn}
            transitionOut={transitionOut}
        >
            <Flex
                flexDirection={{ _: 'column', S: 'row' }}
                alignItems="flex-start"
                mb={{ _: '1rem', S: '6rem' }}
            >
                <Box
                    flex={1}
                    mr={{ _: 0, S: '1.5rem' }}
                    mb={{ _: '1rem', S: 0 }}
                    px={{ _: '0.75rem', S: 0 }}
                >
                    <IntroParagraph>
                        Beryl Chung is a creative director, illustrator, and
                        designer working in digital media & advertising. She is
                        currently based in Amsterdam as a Global Creative
                        Director at Media.Monks.
                    </IntroParagraph>
                    <Paragraph>
                        Beryl is a born and raised New Yorker with a passion for
                        art, digital advertising and Elvis Presley. Introduced
                        to information design through comic books, she came to
                        understand the key to good UX early on in life. While
                        working at Ogilvy Shanghai she changed her focus on
                        digital, learning to embrace both the human and
                        technology side of advertising.
                    </Paragraph>
                    <Paragraph>
                        Fast forward to a few years and countless karaoke nights
                        later, and Beryl's bringing empathy back to interaction.
                        Beryl's focus throughout her career has been on
                        leveraging advances in tech to create more natural,
                        humanistic engagement in digital. Her experience extends
                        across industries and clients, producing award-winning
                        work for brands including Pokémon, Google, TikTok, Meta,
                        and Netflix.
                    </Paragraph>
                    <FeaturedLinks>
                        Featured in:{' '}
                        {featuredLinks.map(({ name, url }, index) => {
                            const isLastItem = index + 1 < featuredLinks.length
                            return (
                                <span key={name}>
                                    <Link target="_blank" href={url}>
                                        {name}
                                    </Link>
                                    {isLastItem && ', '}
                                </span>
                            )
                        })}
                    </FeaturedLinks>
                </Box>

                <Box
                    pl={{ _: 0, S: '6.25rem' }}
                    width={{ _: '100%', S: '25rem' }}
                >
                    <div>
                        <Image
                            alt="beryl chung portrait"
                            src={BerylPortraitImg}
                        />
                    </div>
                </Box>
            </Flex>
            <Flex flexDirection={{ _: 'column', S: 'row' }}>
                <Box flex={1} mr={{ _: 0, S: '1.5rem' }}>
                    <Flex alignItems="flex-end">
                        <Box key={'minerals'} flex={3}>
                            <Image alt="beryl minerals" src={MineralsImg} />
                        </Box>
                        <ResponsiveDisplayBox
                            key="periodic-table"
                            flex={3}
                            display={{ _: 'none', S: 'block' }}
                        >
                            <Image
                                alt="beryl periodic table"
                                src={PeriodicTableImg}
                            />
                        </ResponsiveDisplayBox>
                        <ResponsiveDisplayBox
                            key="geometric-beryl"
                            flex={2}
                            display={{ _: 'none', S: 'block' }}
                        >
                            <Image
                                alt="beryl geometric shape"
                                src={BerylGeometricImg}
                            />
                        </ResponsiveDisplayBox>
                    </Flex>
                </Box>
                <Box
                    flexBasis={{ _: '100%', S: '25rem' }}
                    p={{ _: '0.75rem', S: 0 }}
                >
                    <WikiText fontWeight={700}>
                        Beryl is a mineral composed of beryllium aluminium
                        cyclosilicate with the chemical formula
                        Be&#8323;Al&#8322;(SiO3)&#8326;.
                    </WikiText>
                    <Paragraph>
                        Naturally occurring, hexagonal crystals of beryl can be
                        up to several meters in size. Notable gemstones which
                        contain beryllium include beryl (aquamarine, emerald)
                        and chrysoberyl. As a free element beryllium is a
                        steel-gray, strong, lightweight and brittle alkaline
                        earth metal.
                    </Paragraph>
                </Box>
            </Flex>
        </SecondaryPage>
    )
}

export default About
