import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { space, position, display } from 'styled-system'
import { Flex } from 'rebass/styled-components'

// components
import Footer from '../Footer/Footer'

const Page = styled.div.attrs(({ dropdown, dropdownActive }) => ({
    position: 'fixed',
    pt: { _: '3.75rem', S: '6.25rem' },
}))`
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 20;
    background: #f6f6f6;
    overflow-y: scroll;

    display: block;
    transform: translateY(-100%);
    transition: transform 0.25s ease;

    ${({ dropdown }) =>
        dropdown &&
        `
        transform: translateY(0%);
    `};

    ${position};
    ${display};
    ${space};
`

const ContentContainer = styled(Flex).attrs(() => ({
    flexDirection: 'column',
    width: 1,
    justifyContent: 'space-between',
}))`
    position: relative;
    height: 100%;
    width: 100%;
`

const PageContent = styled.div.attrs(() => ({
    pt: { _: '1.5rem', S: '3rem' },
    pb: { _: '1rem', S: '2rem' },
    px: { _: 0, S: '1.5rem' },
}))`
    margin: 0 auto;
    width: 100%;
    max-width: ${({ theme }) => theme.breakpoints.M};
    ${space};
`

const FooterContainer = styled.div`
    width: 100%;
    bottom: 0;
`

const SecondaryPage = ({ children, transitionIn, transitionOut }) => {
    const [dropdownActive, setDropdownActive] = useState(transitionIn)
    const [dropoutActive, setDropoutActive] = useState(transitionOut)

    useEffect(() => {
        setDropdownActive(transitionIn)
    }, [transitionIn])
    useEffect(() => {
        setDropoutActive(transitionOut)
    }, [transitionOut])

    return (
        <Page
            dropdown={transitionIn}
            dropdownActive={dropdownActive}
            dropout={transitionOut}
            dropoutActive={dropoutActive}
        >
            <ContentContainer>
                <PageContent flex={1}>{children}</PageContent>
                <FooterContainer>
                    <Footer />
                </FooterContainer>
            </ContentContainer>
        </Page>
    )
}

export default SecondaryPage
