import * as React from 'react'
function SvgBgsc(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="357.99 271.82 124.76 50.4"
            {...props}
        >
            <g fill="currentColor">
                <path d="M431.62 301.32c.48 0 .87-.07 1.18-.22.31-.14.54-.34.71-.58.17-.24.29-.53.35-.85.06-.32.09-.65.09-.99v-.34h2.48v.34c0 1.57-.41 2.77-1.23 3.62-.82.85-2.01 1.27-3.58 1.27-1.5 0-2.69-.46-3.57-1.37-.88-.91-1.32-2.25-1.32-4.01v-2.94c0-.84.12-1.6.34-2.27.23-.67.55-1.24.98-1.69.43-.46.94-.81 1.54-1.06.6-.24 1.28-.37 2.03-.37.78 0 1.47.11 2.07.34.6.23 1.11.55 1.51.98.41.43.72.94.92 1.54.21.6.31 1.28.31 2.03v.34h-2.48v-.34c0-.31-.04-.63-.11-.94s-.2-.6-.38-.85-.42-.45-.72-.61c-.3-.16-.68-.23-1.13-.23-.41 0-.77.08-1.07.23-.3.16-.55.37-.75.64-.2.27-.35.58-.44.94-.09.36-.14.73-.14 1.12v3.31c0 .43.04.82.13 1.18s.23.67.41.93c.19.26.44.47.74.61.32.17.69.24 1.13.24zM438.49 290.15h2.48v5.38h4.25v-5.38h2.48v13.17h-2.48v-5.42h-4.25v5.42h-2.48v-13.17zM459.35 290.15v8.62c0 1.51-.4 2.68-1.19 3.54-.8.85-1.98 1.28-3.55 1.28-1.57 0-2.75-.43-3.55-1.28-.8-.85-1.19-2.03-1.19-3.54v-8.62h2.48v8.62c0 .78.18 1.39.55 1.83.36.44.93.66 1.71.66.78 0 1.35-.22 1.71-.66.36-.44.55-1.05.55-1.83v-8.62h2.48zM467.91 302.07h.34v-11.93h2.48v13.17h-4.85l-1.54-11.93H464v11.93h-2.48v-13.17h4.85l1.54 11.93zM476.77 296.65h5.64v6.66h-2.26v-1.47h-.34c-.15.49-.44.9-.87 1.23-.42.33-1.06.5-1.92.5-.6 0-1.17-.11-1.69-.34a3.76 3.76 0 01-1.37-1.01c-.39-.44-.7-1-.92-1.65-.23-.66-.34-1.43-.34-2.31v-3.08c0-.85.12-1.61.36-2.27.25-.66.58-1.21 1.02-1.66.43-.45.95-.79 1.55-1.03.6-.23 1.27-.35 2-.35.75 0 1.42.12 2.01.37.59.25 1.09.58 1.51 1.02.42.43.73.94.94 1.52.21.58.32 1.21.32 1.87v.23h-2.48v-.23c0-.79-.2-1.39-.61-1.8-.41-.41-.98-.61-1.72-.61-.7 0-1.28.24-1.73.71-.45.47-.68 1.28-.68 2.42v2.78c0 2.03.83 3.05 2.48 3.05.74 0 1.3-.2 1.68-.59.38-.39.57-.92.57-1.59h-3.16l.01-2.37z" />
                <g>
                    <path d="M433.02 315.9v1.86h-1.79v1.34c.15-.05.43-.16.86-.33.42-.17.71-.29.85-.33.05.3.1.74.15 1.34.1.4.15.67.15.82-.2.1-.5.22-.89.37-.99.35-1.64.62-1.94.82-.4.15-.82.3-1.26.45l-1.34-1.71c.45-.25.67-.72.67-1.41v-1.34h-1.79v-1.86h1.79v-1.04h-1.26v-1.12c-.3-.3-.57-.64-.82-1.04.84-.94 1.59-2.28 2.23-4.02l2.76.3c-.15.45-.32.89-.52 1.34h2.31v1.79h-3.27c-.05.1-.14.25-.26.45s-.21.35-.26.45h3.5v1.86h-1.64v1.04l1.77-.03zm11.17-4.62v7.44h-2.61v-.75h-1.41v4.09h-2.68v-4.09h-1.34v.75h-2.68v-7.44h4.02v-2.38h2.68v2.38h4.02zm-8.04 1.94v2.83h1.34v-2.83h-1.34zm5.43 0h-1.41v2.83h1.41v-2.83zM463.54 310.31v2.23h-13.03v2.6c.1 3.08-.62 5.41-2.16 7-1.09-.54-2.04-1.02-2.83-1.41.79-.84 1.29-1.6 1.49-2.27.2-.67.3-1.77.3-3.31v-4.84h6.63c-.05-.15-.14-.41-.26-.78a3.98 3.98 0 00-.33-.78l3.94-.22c.05.3.15.7.3 1.19.1.3.15.5.15.6l5.8-.01zM467.64 308.97v5.96c.1 3.52-.35 5.96-1.34 7.3-.25-.1-.65-.35-1.19-.75-.4-.3-.67-.5-.82-.6.79-.89 1.14-2.88 1.04-5.96v-5.96h2.31zm2.75.52v11.61h-2.16v-11.61h2.16zm2.83-.52v11.39c2.68-.45 3.87-1.59 3.57-3.42v-2.68h2.38v2.83c.05.55 0 1.04-.15 1.49 1.44.65 2.68 1.27 3.72 1.86l-1.41 1.71c-.35-.15-.88-.43-1.6-.85-.72-.42-1.25-.73-1.6-.93-.65.79-1.89 1.41-3.72 1.86-.05-.05-.15-.17-.3-.37-.45-.5-.74-.87-.89-1.12v1.19h-2.23v-12.95l2.23-.01zm9.3.3v1.86h-3.42l-.22.89h3.27v6.7h-2.31v-4.91h-3.72v4.99h-2.23v-6.77h2.38l.15-.89h-2.75v-1.86h8.85v-.01z" />
                </g>
                <g>
                    <path d="M426.97 282.62h1.24v-8.43h-1.24v-2.37h5.77c.63 0 1.18.09 1.66.26.48.18.89.42 1.22.72.33.3.58.66.75 1.08.17.42.25.88.25 1.37v.23c0 .75-.21 1.36-.63 1.81-.42.45-.95.77-1.59.94v.34c.64.18 1.17.49 1.59.95.42.46.63 1.06.63 1.82v.23c0 .49-.08.94-.25 1.36-.17.42-.42.79-.75 1.09s-.74.55-1.22.72c-.48.18-1.04.26-1.66.26h-5.77v-2.38zm3.73-5.41h1.81c.52 0 .95-.11 1.28-.33.33-.22.49-.59.49-1.12v-.11c0-.52-.16-.9-.49-1.12-.33-.22-.75-.33-1.28-.33h-1.81v3.01zm0 5.41h1.81c.52 0 .95-.11 1.28-.34.33-.23.49-.6.49-1.13v-.11c0-.53-.16-.9-.49-1.13-.33-.23-.75-.34-1.28-.34h-1.81v3.05zM439.16 271.82h8.32v2.37h-5.83v3.01h5.61v2.37h-5.61v3.05h6.06V285h-8.54v-13.18zM459.35 285h-2.48v-3.95c0-.3-.08-.55-.24-.74-.16-.19-.42-.28-.78-.28h-2.86V285h-2.48v-13.17h5.04c.68 0 1.27.09 1.79.28.51.19.94.45 1.29.78.34.33.61.72.79 1.16.18.44.27.91.27 1.41v.45c0 .54-.14 1.04-.43 1.52-.29.47-.73.83-1.34 1.08v.34c.44.05.79.22 1.04.51.26.29.39.65.39 1.09V285zm-6.36-7.34h2.37c.6 0 1.06-.14 1.37-.42.31-.28.47-.68.47-1.19v-.23c0-.44-.16-.82-.47-1.14-.31-.32-.77-.48-1.37-.48h-2.37v3.46zM465.16 280.37h-.56l-3.78-8.54h2.71l2.71 6.28h.34l2.71-6.28H472l-3.78 8.54h-.56V285h-2.48l-.02-4.63zM473.7 271.82h2.48v10.8h5.98V285h-8.47l.01-13.18z" />
                </g>
            </g>
            <g fill="currentColor">
                <path d="M375.79 320.1l6.06.45-13.62.48 7.56-.93zm-7.63-7.45l6.7 6.7-15.25 1.87 8.55-8.57zm-7.3-7.3l6.68 6.68-8.54 8.57 1.86-15.25zm15.87 13.97l17.1-17.1.06-.09.07-.14 13.09.67-17.6 17.6-12.72-.94zm-17.07-20.97l.45 6.05-.93 7.57.48-13.62zm12.87-5.24l.06-.09 5.73-5.73 6.94 6.94-.86.86.63.62.86-.86 6.95 6.95.1.05-5.76 5.76-1.62-1.62-.57.57 1.62 1.62-10.88 10.88-7-7 .79-.79-.62-.62-.79.79-6.97-6.97 10.88-10.88.02.02.49-.5zm31.7-8.15l-1.26-1.26 4.56-9.27-.06 13.77-1.53-1.53-.57.57 1.01 1.02-1.5 1.5.62.62 1.96-1.96-.06 13.41-13.05-.66.43-.88.4.4 1.86-1.86-.62-.62-1.21 1.21 7.36-14.96 1.07 1.06.59-.56zm2.62-11.14l-4.54 9.22-1.05-1.05-.57.57 1.25 1.25-8.52 17.31-.14-.01-6.83-6.83.5-.5-.63-.62-.5.5-6.83-6.83 17.4-8.56 2.39 2.39-7.24 7.27.63.62 14.68-14.73zm-9.68 4.07l8.6-4.23-6.4 6.42-2.2-2.19zm-34.82 12.9l-2.06-.38 17.04-17.04.66 13-6.18 6.18-2.09-.39-.08.83 1.46.27-10.22 10.22-.9-12.28 2.29.43.08-.84zm32.51-15.2l-2.85-2.85 13.77-.06-24.99 12.3 1.21-1.21-.63-.62-1.86 1.86.4.4-1.09.54-.67-13.15 13.63-.06-1.96 1.96.62.62 1.5-1.5 2.34 2.34.58-.57zm12.8-3.77l-29.99.12-.26.1-18.21 18.21-.1.23-1.12 31.32.43.43 31.28-1.11.25-.1 18.21-18.21.11-.25.12-29.96v-.05l-.72-.73z" />
                <path d="M384.816 279.712l.621.624-1.862 1.856-.622-.623zM388.248 276.24l.62.624-1.862 1.857-.621-.624zM398.638 297.263l-.622-.623 1.861-1.858.622.623zM402.076 293.827l-.622-.622 1.86-1.86.622.623zM380.828 298.704l1.928-1.933.623.621-1.928 1.933zM387.966 291.511l1.927-1.934.623.621-1.926 1.934zM374.304 306.48l-.624-.62 1.924-1.936.625.62zM379.81 300.96l-.07-.07.36-.35-2.04-2.03-.57.57 1.47 1.47-1.71 1.72.63.62zM372.668 308.145l-1.933 1.942-.624-.621 1.933-1.942zM364.67 291.22l-.08.83 2.74.51.08-.83zM383.275 304.857l-2.03-2.03.573-.572 2.03 2.03zM388.6 313.38l-.54-2.74-.83.1.54 2.73zM374.3 294.759l2.03 2.03-.574.572-2.029-2.03zM389.61 318.43l-.54-2.74-.83.1.54 2.73z" />
            </g>
        </svg>
    )
}
export default SvgBgsc
