import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import {
    height,
    display,
    space,
    position,
    color,
    fontSize,
    opacity,
    background,
} from 'styled-system'
import { Flex, Box } from 'rebass/styled-components'
import { NavLink, useLocation } from 'react-router-dom'

// assets
import BerylLogoIcon from '../../globals/icons/components/Bgsc'
import InstagramIcon from '../../globals/icons/components/Instagram'
import FacebookIcon from '../../globals/icons/components/Facebook'
import LinkedinIcon from '../../globals/icons/components/Linkedin'

const socialIcons = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    linkedin: LinkedinIcon,
}

const navigation = [
    {
        label: 'PROJECTS',
        route: '/',
    },
    {
        label: 'ABOUT',
        route: '/about',
    },
    {
        label: 'CONTACT',
        route: '/contact',
    },
]
const socials = [
    {
        icon: 'instagram',
        url: 'https://www.instagram.com/berylchvng/',
    },
    {
        icon: 'linkedin',
        url: 'https://www.linkedin.com/in/beryl-chung-91b82b80/',
    },
]

// styled components
const Container = styled.header`
    position: fixed;
    width: 100%;
    z-index: 50;
    transition: background-color 0.25s ease;
    background-color: ${({ isLightMode }) =>
        isLightMode ? 'rgba(246,246,246, 0.85)' : 'transparent'};
`

const Content = styled(Flex).attrs(() => ({
    height: {
        _: '3.75rem',
        S: '6.25rem',
    },
    justifyContent: 'space-between',
    alignItems: 'center',
    mx: 'auto',
    px: { _: '0.5rem', S: '1.5rem' },
}))`
    width: 100%;
    max-width: 1360px;
    ${height};
    ${space};
`

const pulsingKeyframes = keyframes`
    0% { opacity: 1 }
    50% { opacity: 0 }
    100% { opacity: 1 }
`
const pulsingAnimation = css`
    animation: ${pulsingKeyframes} 2s ease 2s infinite;
`
const HomeIcon = styled(({ isLoading, isLightMode, ...rest }) => (
    <NavLink {...rest} />
))`
    vertical-align: top;
    display: inline-block;
    height: 42px;
    cursor: pointer;
    color: ${({ isLightMode }) => (isLightMode ? '#000000' : '#FFFFFF')};
    pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
    ${({ isLoading }) => (isLoading ? pulsingAnimation : '')};
    transition: color 0.25s ease;
`

const NavContainer = styled.div.attrs(({ isOpen, isLoading }) => ({
    position: { _: 'fixed', S: 'relative' },
    flex: 1,
    height: { _: isOpen ? '100%' : 0, S: 'initial' },
    backgroundColor: { _: 'rgba(0, 0, 0, 0.85)', S: 'transparent' },
    opacity: isLoading ? 0 : 1,
}))`
    top: 0;
    left: 0;
    right: 0;
    transition: height 0.25s ease, opacity 0.25s ease;
    overflow: hidden;
    ${position};
    ${height};
    ${color};
    ${opacity};
`
const NavContent = styled(Flex).attrs(() => ({
    as: 'nav',
    flexDirection: { _: 'column', S: 'row' },
    justifyContent: { _: 'flex-start', S: 'flex-end' },
    alignItems: 'center',
    pt: { _: '5.75rem', S: 0 },
}))``

const NavItem = styled(Box).attrs(() => ({
    mr: { _: 0, S: '2.5rem' },
    mb: { _: '3rem', S: 0 },
}))`
    position: relative;
`

const StyledLink = styled(NavLink)`
    text-decoration: none;
`

const NavLinkLabel = styled.span.attrs(({ isLightMode }) => ({
    fontSize: { _: '1rem', S: '0.75rem' },
    color: { _: '#fff', S: isLightMode ? '#000' : '#fff' },
    background: { _: '#fff', S: isLightMode ? '#000' : '#fff' },
}))`
    text-decoration: none;
    letter-spacing: 2.4px;
    font-weight: 700;
    padding: 0.125rem;
    transition: color 0.25s ease;
    font-family: 'Space Mono', monospace;
    ${fontSize};
    ${color};

    &:after {
        content: '';
        position: absolute;
        top: 24px;
        left: 0;
        width: 100%;
        height: ${({ isActive }) => (isActive ? '2px' : 0)};
        ${background};
        transition: height 0.25s ease, background 0.25s ease;
    }
`

const NavSocialContainer = styled.div`
    display: inline-block;
    vertical-align: top;
`

const NavSocialItem = styled.a.attrs(({ isLightMode }) => ({
    height: { _: '2rem', S: '1.25rem' },
    mr: { _: '2.5rem', S: '1.25rem' },
    color: { _: '#fff', S: isLightMode ? '#000' : '#fff' },
}))`
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    font-size: 20px;
    height: 20px;
    transition: color 0.1s ease;
    text-decoration: none;
    &:hover {
        color: #c2912e;
    }
    &:not(:last-child) {
        ${space};
    }
    span {
        vertical-align: top;
    }
    ${fontSize};
    ${height};
    ${color};
`

const MobileNavToggle = styled.span.attrs(() => ({
    display: { _: 'inline-block', S: 'none' },
}))`
    ${display};
    position: absolute;
    right: 10px;
    top: 9px;
    width: 36px;
    height: 42px;
    padding: 7px 4px;
    z-index: 52;
`

const hamburgerBarStyle = css`
    width: 28px;
    height: 2px;
    border-radius: 2px;
    position: absolute;
    opacity: 1;
    transition: all 0.15s ease;
`
const MobileNavHamburger = styled.span.attrs(({ isLightMode, showAsX }) => ({
    backgroundColor: isLightMode && !showAsX ? '#000' : '#fff',
}))`
    display: block;
    top: 50%;
    margin-top: -1px;
    transition: color 0.1s ease;
    ${hamburgerBarStyle};
    ${color};
    ${({ showAsX }) => showAsX && `height: 0;`};

    &:before,
    &:after {
        content: '';
        display: block;
        transition: color 0.1s ease;
        ${color};
        ${hamburgerBarStyle};
    }
    &:before {
        top: -9px;
        ${({ showAsX }) =>
            showAsX &&
            `
            top: -1px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        `};
    }
    &:after {
        bottom: -9px;
        ${({ showAsX }) =>
            showAsX &&
            `
            bottom: -1px;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
        `};
    }
`

const pathnameUsesLightMode = (pathname) =>
    ['/about', '/contact'].includes(pathname)

const Header = ({ isLoaded }) => {
    // router hooks
    const { pathname } = useLocation()

    // state hooks
    const [navOpen, setNavOpen] = useState(false)
    const [isLightMode, setIsLightMode] = useState(
        pathnameUsesLightMode(pathname)
    )

    // effect hooks
    useEffect(() => {
        setNavOpen(false)

        // for secondary pages, turn on "light mode"
        setIsLightMode(pathnameUsesLightMode(pathname))
    }, [pathname])

    return (
        <Container isLightMode={isLoaded && isLightMode}>
            <Content>
                <HomeIcon
                    to="/"
                    isLoading={!isLoaded}
                    isLightMode={isLightMode}
                >
                    <BerylLogoIcon height="100%" />
                </HomeIcon>

                <NavContainer isOpen={navOpen} isLoading={!isLoaded}>
                    <NavContent>
                        {navigation.map(({ label, route }) => (
                            <NavItem key={`${route}-nav`}>
                                <StyledLink to={route}>
                                    <NavLinkLabel
                                        isActive={route === pathname}
                                        isLightMode={isLightMode}
                                    >
                                        {label}
                                    </NavLinkLabel>
                                </StyledLink>
                            </NavItem>
                        ))}
                        <NavSocialContainer>
                            {socials.map(({ icon, url }) => {
                                const Icon = socialIcons[icon]
                                return (
                                    <NavSocialItem
                                        key={icon}
                                        href={url}
                                        target="_blank"
                                        isLightMode={isLightMode}
                                    >
                                        <Icon height="100%" />
                                    </NavSocialItem>
                                )
                            })}
                        </NavSocialContainer>
                    </NavContent>
                </NavContainer>

                <MobileNavToggle onClick={() => setNavOpen(!navOpen)}>
                    <MobileNavHamburger
                        isLightMode={isLightMode}
                        showAsX={navOpen}
                    ></MobileNavHamburger>
                </MobileNavToggle>
            </Content>
        </Container>
    )
}

Header.propTypes = {
    loaded: PropTypes.bool,
}

export default Header
