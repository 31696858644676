import * as React from 'react'
function SvgAnthemAwards(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
            <path
                d="M13.73 0L0 23.781h27.46zM9.155 10.726l10.934 2.181-1.395 2.36-2.53-.545-1.99 3.37 1.695 1.954-1.347 2.278-7.163-8.558zm.942 2.663l2.58 2.98 1.265-2.14zm31.489-2.648L37.824.011h-3.598l-3.795 10.73h2.699l.834-2.502h3.99l.835 2.502zm-5.61-8.521l1.259 3.81H34.7zm14.25 5.61L46.579.012h-3.942v10.73h2.519v-7.82l3.68 7.818h3.941V.011h-2.551zM62.794.012h-8.75v2.404h3.058v8.325h2.617V2.416h3.075zm3.895 10.73V6.537h4.268v4.203h2.617V.011h-2.617v4.106H66.69V.012h-2.617v10.73zM78.06 6.455h4.17v-2.29h-4.187V2.383h4.989V.012h-7.59v10.73h7.803V8.336H78.06zM97.564.012h-4.301L91.104 8.01 88.961.012H84.66v10.73h2.551l-.114-8.31 2.355 8.31h3.271l2.372-8.375-.115 8.374h2.584zm-55.978 23.73l-3.762-10.73h-3.598l-3.795 10.73h2.699l.834-2.503h3.99l.835 2.502zm-5.61-8.522l1.259 3.81H34.7zm16.658 6.199l-1.979-8.407h-3.173l-2.028 8.407-1.684-8.407h-2.765l2.47 10.73h3.86l1.7-7.017 1.67 7.016h3.86l2.502-10.73h-2.7zm15.018 2.322l-3.762-10.73h-3.598l-3.794 10.73h2.698l.835-2.502h3.99l.834 2.502zm-5.61-8.521l1.26 3.81h-2.535zm13.695 3.663c.866-.408 1.57-1.16 1.57-2.551 0-2.306-1.734-3.32-3.86-3.32h-4.743v10.73h2.617v-3.714h1.995c1.063 0 1.276.41 1.276 1.243v2.47h2.617v-2.764c0-1.145-.295-1.897-1.472-2.094zM71.32 18v-2.65h1.602c1.178 0 1.636.524 1.636 1.31 0 .784-.458 1.34-1.636 1.34zm11.79-4.988h-4.12v10.73h4.121c3.124 0 5.43-2.029 5.43-5.43 0-3.436-2.306-5.3-5.43-5.3zm-1.504 8.308v-5.904h1.243c1.684 0 2.944.801 2.944 2.895s-1.276 3.01-2.944 3.01zm7.652.278c.997 1.456 2.682 2.307 4.677 2.307 2.274 0 4.155-1.194 4.155-3.435 0-1.783-1.227-2.797-3.108-3.238l-1.096-.246c-.916-.229-1.44-.441-1.44-.981 0-.605.672-.834 1.424-.834 1.178 0 1.766.523 2.192 1.226l1.93-1.7c-.802-1.03-2.143-1.849-4.09-1.849-2.11 0-4.072.982-4.072 3.222 0 1.62 1.014 2.83 3.01 3.255l1.128.245c1.047.23 1.488.54 1.488 1.047 0 .638-.637.965-1.52.965-1.325 0-2.274-.769-2.847-1.734z"
                fill="#fff"
            />
        </svg>
    )
}
export default SvgAnthemAwards
