import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

// components
import { Paragraph } from '../../../../globals/Typography'

// styled components
const inputStyle = css`
    width: 100%;
    font-size: 1.125rem;
    outline: none;
    box-shadow: none;
    background: #f6f6f6;
    border-radius: 0;
    transition: border-color 0.1s ease;
    border-color: ${({ hasError }) => (hasError ? '#D8000D' : '#000')};
    &:focus {
        border-color: ${({ hasError }) => (hasError ? '#D8000D' : '#c2912e')};
    }
`

const Input = styled.input.attrs(() => ({}))`
    border: none;
    border-bottom: 1px solid;
    height: 2rem;
    ${inputStyle};
`

const TextArea = styled.textarea`
    border: 1px solid;
    resize: none;
    height: 6.25rem;
    padding: 0.5rem;
    ${inputStyle};
`

const INPUT_COMPONENTS = {
    input: Input,
    textarea: TextArea,
}
const TextField = ({
    label,
    as,
    input,
    meta: { error, submitFailed } = {},
    ...additionalProps
}) => {
    const InputComponent = useMemo(() => INPUT_COMPONENTS[as] || Input, [as])

    return (
        <>
            <InputComponent
                hasError={submitFailed && error}
                {...input}
                {...additionalProps}
            />
            {label && <Paragraph fontSize="0.75rem">{label}</Paragraph>}
        </>
    )
}

export default TextField
