import styled, { css } from 'styled-components'

const variantStyles = {
    solid: css`
        background: #000;
        color: #fff;
        border: 1px solid #000;
    `,
    outline: css`
        background: transparent;
        color: #fff;
        border: 1px solid #fff;
    `,
}

export const buttonStyles = css`
    display: inline-block;
    width: 8rem;
    padding: 0.5rem 0;
    outline: none;
    text-align: center;
    font-family: 'Space Mono', monospace;
    font-size: 12px;
    text-decoration: none;
    &:hover {
        cursor: pointer;
    }
    ${({ variant }) => variantStyles[variant] || variantStyles.outline};
`

const Button = styled.button.attrs(({ href }) => ({
    as: href ? 'a' : 'button',
}))`
    ${buttonStyles};
`

export default Button
