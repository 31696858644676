import React, { useMemo } from 'react'
import YouTubePlayer from 'react-player/lib/players/YouTube'
import VimeoPlayer from 'react-player/lib/players/Vimeo'
import styled from 'styled-components'

const PLAYERS = {
    youtube: YouTubePlayer,
    vimeo: VimeoPlayer,
}

// styled
const VideoContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${({ isActive }) => (isActive ? 3 : -1)};
`

const VideoPlayer = ({ url, player, isActive, controls = true }) => {
    const Player = useMemo(() => PLAYERS[player], [player])

    return (
        <VideoContainer isActive={isActive}>
            <Player
                url={url}
                playing={isActive}
                controls={controls}
                width="100%"
                height="100%"
                config={{
                    youtube: {
                        preload: true,
                        playerVars: {
                            color: 'white',
                            modestbranding: 1,
                            rel: 0,
                        },
                    },
                    vimeo: {
                        preload: true,
                    },
                }}
            />
        </VideoContainer>
    )
}

export default VideoPlayer
