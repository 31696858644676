import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { width, height } from 'styled-system'

// components
import Hero from './components/Hero/Hero'
import Project from './components/Project/Project'
import Footer from '../../globals/Footer/Footer'

// assets
import chatScreenPreview from './images/chatScreen.jpg'
import lionessScreenPreview from './images/lionessScreen.jpg'
import pumaScreenPreview from './images/pumaScreen.jpg'
import st3NytScreenPreview from './images/st3NytScreen.jpg'
import wommenInTechScreenPreview from './images/wommenInTechScreen.jpg'
import yungSeriesPreview from './images/yungSeries.gif'
import empathyExperimentPreview from './images/empathyExperiment.jpg'
import googleAssistantPreview from './images/googleAssistantCookie.gif'
import googleCloudMlbPreview from './images/googleCloudMlb.gif'
import metaHorizonPreview from './images/metaHorizon.png'
import netflixGeekedPreview from './images/netflixGeeked.png'
import pokemon25Preview from './images/pokemon25.jpg'
import tiktokPridePreview from './images/tiktokPride.jpeg'

const heroProject = {
    id: 'empathy-experiment',
    title: 'The Empathy Experiment',
    website: 'https://www.empathyexperiment.com/',
    preview: empathyExperimentPreview,
    awards: ['anthemAwards', 'fwa', 'cssWinner'],
}
const projects = [
    {
        id: 'meta-horizon-world',
        title: (
            <span>
                Meta Horizon Worlds:
                <br />
                Creepy Cabin
            </span>
        ),
        video: {
            url: 'https://www.youtube.com/watch?v=96DZ0z5vMk4',
            player: 'youtube',
        },
        preview: metaHorizonPreview,
        awards: [],
    },
    {
        id: 'post-malone-pokemon',
        title: 'Post Malone x Pokémon 25',
        video: {
            url: 'https://www.youtube.com/watch?v=TEAc9IzREQY',
            player: 'youtube',
        },
        preview: pokemon25Preview,
        awards: ['cannesLions', 'clio', 'oneShow', 'webbyAwards'],
    },
    {
        id: 'netflix-geeked-cowboy-beebop',
        title: (
            <span>
                Netflix Geeked
                <br />
                Cowboy Beebop: Unlocked
            </span>
        ),
        video: {
            url: 'https://www.youtube.com/watch?v=ewAINI1az1w',
            player: 'youtube',
        },
        preview: netflixGeekedPreview,
        awards: [],
    },
    {
        id: 'tiktok-freetobepride',
        title: 'TikTok #FreeToBePride',
        video: {
            url: 'https://www.youtube.com/watch?v=X0hd8cGtOzc',
            player: 'youtube',
        },
        preview: tiktokPridePreview,
        awards: ['campaignExperienceAward'],
    },
    {
        id: 'google-cloud-mlb-big-data',
        title: (
            <span>
                Google Cloud x MLB
                <br />
                Big Data for the Big Leagues
            </span>
        ),
        video: {
            url: 'https://youtu.be/uXRIpxMC8a8',
            player: 'youtube',
        },
        preview: googleCloudMlbPreview,
        awards: [],
    },
    {
        id: 'google-assistant-games-cookie-detective',
        title: (
            <span>
                Google Assistant Games:
                <br />
                Cookie Detective
            </span>
        ),
        website: 'https://developers.google.com/assistant/games/case-studies',
        preview: googleAssistantPreview,
        awards: [],
    },
    {
        id: 'puma-run-my-way',
        title: 'PUMA: Run My Way',
        video: {
            url: 'https://vimeo.com/356156091',
            player: 'vimeo',
        },
        preview: pumaScreenPreview,
        awards: ['fwa', 'spikesAsia'],
    },
    {
        id: 'lioness',
        title: 'Lioness 2.0',
        video: {
            url: 'https://www.youtube.com/watch?v=eoR7383yxPQ',
            player: 'youtube',
        },
        preview: lionessScreenPreview,
        awards: ['fwa'],
    },
    {
        id: 'google-lens',
        title: (
            <span>
                Google Lens:
                <br />
                Stranger Things NYT Ads
            </span>
        ),
        video: {
            url: 'https://vimeo.com/355979831',
            player: 'vimeo',
        },
        preview: st3NytScreenPreview,
        awards: ['fwa', 'clio'],
    },
    {
        id: 'chat-museum',
        title: (
            <span>
                CHAT Museum:
                <br />
                Re-spinning the Yarn
            </span>
        ),
        video: {
            url: 'https://www.youtube.com/watch?v=y1mmovvoabY',
            player: 'youtube',
        },
        preview: chatScreenPreview,
        awards: [],
    },
    {
        id: 'adidas-yung',
        title: "adidas Yung 90's campaign",
        website: 'https://thefwa.com/cases/adidas-yung',
        preview: yungSeriesPreview,
        awards: ['eurobest', 'fwa', 'awwwards'],
    },
    {
        id: 'wommen-in-tech',
        title: (
            <span>
                MediaMonks:
                <br />
                WoMMen in Tech
            </span>
        ),
        video: {
            url: 'https://www.youtube.com/watch?v=V8VveGNqGoU',
            player: 'youtube',
        },
        preview: wommenInTechScreenPreview,
        awards: ['anthemAwards'],
    },
]

// styled components
const Grid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
`

const GridItem = styled.div.attrs(() => ({
    width: {
        _: '100%',
        S: '50%',
        L: '33.33%',
    },
}))`
    ${width};
`

const HeroContainer = styled.div.attrs(() => ({
    height: {
        _: 'inherit',
        S: '24rem',
        L: '28rem',
    },
}))`
    ${height};
    width: 100%;
`

const Projects = ({ isActive, isLoaded }) => {
    const [activeProject, setActiveProject] = useState('')

    useEffect(() => {
        if (!isActive && activeProject) {
            setActiveProject('')
        }
    }, [isActive, activeProject, setActiveProject])

    return (
        <>
            <Hero siteIsLoaded={isLoaded} />
            <Grid>
                <HeroContainer>
                    <Project
                        key={heroProject.id}
                        {...heroProject}
                        setAsActive={setActiveProject}
                        activeProject={activeProject}
                    />
                </HeroContainer>
                {projects.map((project) => (
                    <GridItem>
                        <Project
                            key={project.id}
                            {...project}
                            setAsActive={setActiveProject}
                            activeProject={activeProject}
                        />
                    </GridItem>
                ))}
            </Grid>

            <Footer />
        </>
    )
}

Projects.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
}

export default Projects
