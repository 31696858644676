import React, { useState, useCallback } from 'react'
import { Flex, Box } from 'rebass/styled-components'
import styled from 'styled-components'
import { color } from 'styled-system'
import { Form, Field } from 'react-final-form'
import axios from 'axios'

// components
import SecondaryPage from '../../globals/SecondaryPage/SecondaryPage'
import Button from '../../globals/Button/Button'
import { H2, H3, Link } from '../../globals/Typography'
import TextField from './components/TextField/TextField'

// constants
const ERROR = 'error'

// styled
const EmailLink = styled(Link)`
    display: block;
    text-decoration: underline;
`

const FieldSet = styled(Flex).attrs(() => ({
    flexDirection: 'column',
    mb: { _: '1.5rem', S: '2rem' },
}))``

const Label = styled(H3)`
    margin-bottom: 1rem;
`

const MessageBox = styled.p.attrs(({ variant }) => ({
    backgroundColor: variant === ERROR ? '#ffcccc' : 'transparent',
}))`
    padding: 1rem;
    margin: 0;
    text-align: center;
    ${color};
`

// validations
const required = value => (value ? undefined : 'Required')
const isEmail = emailStr => {
    var emailRegex = /^(.+)@(.+)$/
    var matchArray = emailStr.match(emailRegex)

    if (matchArray == null) {
        return "Email address seems incorrect (check @ and .'s)"
    }

    return ''
}
const composeValidators = (...validators) => value =>
    validators.reduce(
        (error, validator) => error || validator(value),
        undefined,
    )

const Contact = ({ transitionIn, transitionOut }) => {
    // state hook
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [successfullySubmitted, setSuccessfullySubmitted] = useState(false)
    const [submitErrors, setSubmitErrors] = useState(null)

    const submitForm = useCallback(
        ({ firstName, lastName, email, subject, message }, reset) => {
            setSubmitErrors(null)
            setSuccessfullySubmitted(false)
            setIsSubmitting(true)
            axios({
                method: 'POST',
                url:
                    'https://https://mojm3fw2xi.execute-api.us-east-1.amazonaws.com/submit-contact',
                data: {
                    firstName,
                    lastName,
                    email,
                    subject,
                    message,
                },
            })
                .then(() => {
                    setIsSubmitting(false)
                    setSuccessfullySubmitted(true)
                    reset()
                })
                .catch(err => {
                    setIsSubmitting(false)
                    setSubmitErrors(err)
                })
        },
        [setIsSubmitting, setSuccessfullySubmitted, setSubmitErrors],
    )

    return (
        <SecondaryPage
            transitionIn={transitionIn}
            transitionOut={transitionOut}
        >
            <Flex
                width={1}
                flexDirection={{ _: 'column', S: 'row' }}
                mb={{ _: '1rem', S: '6rem' }}
                px={{ _: '0.75rem', S: 0 }}
            >
                <Box width={{ _: 1, S: 0.5 }} mb={{ _: '3rem', S: 0 }}>
                    <H2 mb="1rem">
                        Get in touch using this
                        <br />
                        form or email
                    </H2>
                    <EmailLink href="mailto:hi@berylchung.com?Subject=Hi!">
                        <H2>hi@berylchung.com</H2>
                    </EmailLink>
                </Box>
                <Box width={{ _: 1, S: 0.5 }} mb={{ _: '3rem', S: 0 }}>
                    <Form
                        onSubmit={submitForm}
                        subscription={{}}
                        render={({ handleSubmit, reset }) => (
                            <form
                                id="feedbackForm"
                                onSubmit={e => {
                                    handleSubmit(e, reset) // pass values and reset function to submit func
                                }}
                                noValidate
                            >
                                <FieldSet>
                                    <Label>Name</Label>
                                    <Flex
                                        flexDirection={{
                                            _: 'column',
                                            S: 'row',
                                        }}
                                    >
                                        <Box
                                            width={{ _: 1, S: 0.5 }}
                                            mr={{ _: 0, S: '1rem' }}
                                        >
                                            <Field
                                                component={TextField}
                                                type="text"
                                                name="firstName"
                                                label="First Name"
                                                validate={required}
                                            />
                                        </Box>
                                        <Box width={{ _: 1, S: 0.5 }}>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                name="lastName"
                                                label="Last Name"
                                            />
                                        </Box>
                                    </Flex>
                                </FieldSet>
                                <FieldSet>
                                    <Label>Email Address</Label>
                                    <Field
                                        component={TextField}
                                        type="email"
                                        name="email"
                                        validate={composeValidators(
                                            required,
                                            isEmail,
                                        )}
                                    />
                                </FieldSet>
                                <FieldSet>
                                    <Label>Subject</Label>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        name="subject"
                                        validate={required}
                                    />
                                </FieldSet>
                                <FieldSet>
                                    <Label>Message</Label>
                                    <Field
                                        component={TextField}
                                        as="textarea"
                                        type="text"
                                        name="message"
                                        validate={required}
                                    />
                                </FieldSet>
                                <Flex
                                    mb={
                                        submitErrors || successfullySubmitted
                                            ? '1rem'
                                            : 0
                                    }
                                    justifyContent={{
                                        _: 'center',
                                        S: 'flex-end',
                                    }}
                                >
                                    <Button
                                        type="submit"
                                        variant="solid"
                                        isLoading={isSubmitting}
                                    >
                                        SUBMIT
                                    </Button>
                                </Flex>

                                {submitErrors && (
                                    <MessageBox variant={ERROR}>
                                        Sorry, something went wrong. Please try
                                        again.
                                    </MessageBox>
                                )}

                                {successfullySubmitted && (
                                    <MessageBox>
                                        Success! I will get back to you as soon
                                        as I can.
                                    </MessageBox>
                                )}
                            </form>
                        )}
                    />
                </Box>
            </Flex>
        </SecondaryPage>
    )
}

export default Contact
